export class URLS_FACTORY {
	static URLS_PATHS = {
		HOME: '/home',
		ROOT: '/',
		FORGOT_PASSWORD: '/forgot-password',
		DASHBOARD: '/dashboard',
		DASHBOARD__NOTIICATIONS: 'notifications',
		DASHBOARD__USER: 'user',
		DASHBOARD__USER__PROFILE: 'profile/',
		DASHBOARD__ADMIN: 'admin',
		DASHBOARD__ADMIN__HOME: 'home',
		DASHBOARD__ADMIN__USERS: 'users',
		DASHBOARD__ADMIN__USER: 'user/',
		DASHBOARD__ADMIN__CONTACTS: 'contacts',
		DASHBOARD__ADMIN__CONTACT: 'contact/',
		DASHBOARD__ADMIN__ACTIVITIES: 'activities',
		DASHBOARD__ADMIN__ACTIVITY: 'activity/',
		DASHBOARD__ADMIN__REQUESTS: 'requests/',
		DASHBOARD__ADMIN__TARGETS: 'targets',
		DASHBOARD__ADMIN__TARGET: 'target/',
		DASHBOARD__ADMIN__PROPERTIES: 'properties',
		DASHBOARD__ADMIN__PROPERTY: 'property/',
		DASHBOARD__ADMIN__REQUEST: 'request/',
		DASHBOARD__ADMIN__MATCHING: 'matching',
		DASHBOARD__ADMIN__REMINDERS: 'reminders',
		DASHBOARD__ADMIN__REMINDER: 'reminder/',
		DASHBOARD__ADMIN__SEARCH: 'search'
	}

	static URLS_FULL_PATHS = {
		FULL_DASHBOARD_ADMIN: '/dashboard/admin',
		FULL_DASHBOARD_USER: '/dashboard/user',
		FULL_DASHBOARD_USER_PROFILE: '/dashboard/user/profile/',
		FULL_DASHBOARD_USER_NOTIICATIONS: '/dashboard/user/notifications',
		FULL_DASHBOARD_ADMIN_USER: '/dashboard/admin/user/',
		FULL_DASHBOARD_ADMIN_USERS: '/dashboard/admin/users/',
		FULL_DASHBOARD_ADMIN_CONTACT: '/dashboard/admin/contact/',
		FULL_DASHBOARD_ADMIN_CONTACTS: '/dashboard/admin/contacts/',
		FULL_DASHBOARD_ADMIN_ACTIVITY: '/dashboard/admin/activity/',
		FULL_DASHBOARD_ADMIN_ACTIVITIES: '/dashboard/admin/activities/',
		FULL_DASHBOARD_ADMIN_ACTIVITIES_USER: '/dashboard/admin/activities/user',
		FULL_DASHBOARD_ADMIN_ACTIVITIES_PROPERTY: '/dashboard/admin/activities/property/',
		FULL_DASHBOARD_ADMIN_REQUESTS: '/dashboard/admin/requests/',
		FULL_DASHBOARD_ADMIN_TARGETS: '/dashboard/admin/targets/',
		FULL_DASHBOARD_ADMIN_TARGET: '/dashboard/admin/target/',
		FULL_DASHBOARD_ADMIN_NOTIICATIONS: '/dashboard/admin/notifications',
		FULL_DASHBOARD_ADMIN_PROPERTIES: '/dashboard/admin/properties/',
		FULL_DASHBOARD_ADMIN_PROPERTY: '/dashboard/admin/property/',
		FULL_DASHBOARD_ADMIN_REQUEST: '/dashboard/admin/request/',
		FULL_DASHBOARD_ADMIN_REQUEST_MATCHING: '/dashboard/admin/request/{id}/matching/',
		FULL_DASHBOARD_ADMIN_REMINDERS: '/dashboard/admin/reminders/',
		FULL_DASHBOARD_ADMIN_REMINDER: '/dashboard/admin/reminder/',
		FULL_DASHBOARD_ADMIN_SEARCH: '/dashboard/admin/search'
	}

	static getURL = (url, {
		request_id,
		user_id,
		contact_id,
		activity_id,
		target_id,
		property_id,
		reminder_id,
		search
	} = {}) => {
		switch (url){
			case this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_REQUEST_MATCHING:
				return this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_REQUEST_MATCHING.replace('{id}', request_id);
			case this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_USER:
				return this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_USER + user_id;
			case this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_CONTACT:
				return this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_CONTACT + contact_id;
			case this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_ACTIVITY:
				url = this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_ACTIVITY + activity_id;
				if(property_id){
					url += "?property=" + property_id;
				}
				return url;
			case this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_TARGET:
				return this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_TARGET + target_id;
			case this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_REMINDER:
				return this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_REMINDER + reminder_id;
			case this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_PROPERTY:
				return this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_PROPERTY + property_id;
			case this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_REQUEST:
				return this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_REQUEST + request_id;
			case this.URLS_FULL_PATHS.FULL_DASHBOARD_USER_PROFILE:
				return this.URLS_FULL_PATHS.FULL_DASHBOARD_USER_PROFILE + user_id;
			case this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_ACTIVITIES_PROPERTY:
				return this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_ACTIVITIES_PROPERTY + property_id;
			case this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_SEARCH:
				url = this.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_SEARCH;
				if(search){
					url += "?search=" + search;
				}
				return url;
			default:
				return url;
		}
	}
}