export const WorkerResponse = {
	ERROR: 'error',
	SUCCESS: 'success'
};

export const DecodeResponseType = {
	NOTHING: 0,
	TEXT: 1,
	JSON: 2,
	BLOB: 3
};

export const WorkerResponseCode = {
	LOGIN: 'login',
	LOGOUT: 'logout',
	FORGOT_PWD: 'forgot_password',

	CREATE_USER: 'create_user',
	DELETE_USER: 'delete_user',
	GET_USERS: 'get_users',
	GET_USER: 'get_user',
	UPDATE_USER: 'update_user',

	CREATE_CONTACT: 'create_contact',
	GET_CONTACTS: 'get_contacts',
	GET_CONTACT: 'get_contact',
	UPDATE_CONTACT: 'update_contact',
	DELETE_CONTACT: 'delete_contact',

	DOM_GET_CITIES: 'dom_get_cities',
	DOM_GET_PROPERTY_CATEGORIES: 'dom_get_property_categories',
	LABELS_GET_PROPERTY_CATEGORIES: 'labels_get_property_categories',
	DOM_GET_PROPERTY_ASSIGNS: 'dom_get_property_assigns',
	LABELS_GET_PROPERTY_ASSIGNS: 'labels_get_property_assigns',
	DOM_GET_COUNTRIES: 'dom_get_countries',
	LABELS_GET_COUNTRIES: 'labels_get_countries',
	DOM_GET_PROPERTY_TYPOLOGIES: 'dom_get_property_typologies',
	LABELS_GET_PROPERTY_TYPOLOGIES: 'labels_get_property_typologies',
	DOM_GET_DIRECTIONS: 'dom_get_directions',
	LABELS_GET_DIRECTIONS: 'labels_get_directions',
	DOM_GET_MEASUREMENTS: 'dom_get_measurements',
	LABELS_GET_MEASUREMENTS: 'labels_get_measurements',
	DOM_GET_HEATING_TYPES: 'dom_get_heating_types',
	LABELS_GET_HEATING_TYPES: 'labels_get_heating_types',
	DOM_GET_HEATING_POWERS: 'dom_get_heating_powers',
	LABELS_GET_HEATING_POWERS: 'labels_get_heating_powers',
	DOM_GET_PROPERTY_CONDITIONS: 'dom_get_property_conditions',
	LABELS_GET_PROPERTY_CONDITIONS: 'labels_get_property_conditions',
	DOM_GET_PROPERTY_PURPOSES: 'dom_get_property_purposes',
	LABELS_GET_PROPERTY_PURPOSES: 'labels_get_property_purposes',
	DOM_GET_PROPERTY_REGULATIONS: 'dom_get_property_regulations',
	LABELS_GET_PROPERTY_REGULATIONS: 'labels_get_property_regulations',
	LABELS_GET_PROPERTY_REGULATION_SUBLABELS: 'labels_get_property_regulations_sublabels',
	DOM_GET_PROPERTY_REGULATION_OPTIONS: 'dom_get_property_regulation_options',
	LABELS_GET_PROPERTY_REGULATION_OPTIONS: 'labels_get_property_regulation_options',
	DOM_GET_PROPERTY_OUTDOORS: 'dom_get_property_outdoors',
	LABELS_GET_PROPERTY_OUTDOORS: 'labels_get_property_outdoors',
	DOM_GET_PROPERTY_BENEFITS: 'dom_get_property_benefits',
	LABELS_GET_PROPERTY_BENEFIT_CATEGORIES: 'labels_get_property_benefit_categories',
	DOM_GET_PROPERTY_BENEFITS_BY_CATEGORY: 'dom_get_property_benefits_by_category',
	LABELS_GET_PROPERTY_BENEFITS: 'labels_get_property_benefits',
	DOM_GET_ACTIVITIES: 'dom_get_activities',
	LABELS_GET_ACTIVITIES: 'labels_get_activities',
	DOM_GET_REQUEST_BENEFITS: 'dom_get_request_benefits',
	LABELS_GET_REQUEST_BENEFITS: 'labels_get_request_benefits',
	DOM_GET_TIMEFRAMES: 'dom_get_timeframes',
	LABELS_GET_TIMEFRAMES: 'labels_get_timeframes',
	DOM_GET_TIMEFRAMES_FILTERS: 'dom_get_timeframes_filters',
	LABELS_GET_TIMEFRAMES_FILTERS: 'labels_get_timeframes_filters',
	DOM_GET_NEWS_STATUSES: 'dom_get_news_statuses',
	LABELS_GET_NEWS_STATUSES: 'labels_get_news_statuses',
	DOM_GET_TAGS: 'dom_get_tags',
	LABELS_GET_TAGS: 'labels_get_tags',

	PROPERTIES_GET_PROPERTIES: 'properties_get_properties',
	PROPERTIES_CREATE_PROPERTY: 'properties_create_property',
	PROPERTIES_UPDATE_PROPERTY: 'properties_update_property',
	PROPERTIES_GET_PROPERTY: 'properties_get_property',
	PROPERTIES_GET_LATEST_PROPERTIES: 'properties_get_latest_properties',
	PROPERTIES_GET_REQUEST_MATCHES: 'properties_get_request_matches',

	REQUESTS_GET_REQUESTS: 'requests_get_requests',
	REQUESTS_CREATE_REQUEST: 'requests_create_request',
	REQUESTS_UPDATE_REQUEST: 'requests_update_request',
	REQUESTS_GET_REQUEST: 'requests_get_request',

	ACTIVITIES_GET_ACTIVITIES: 'activities_get_activities',
	ACTIVITIES_CREATE_ACTIVITY: 'activities_create_activity',
	ACTIVITIES_UPDATE_ACTIVITY: 'activities_update_activity',
	ACTIVITIES_GET_ACTIVITY: 'activities_get_activity',
	ACTIVITIES_GET_CONTACT_ACTIVITIES: 'activities_get_contact_activities',
	ACTIVITIES_GET_PROPERTY_ACTIVITIES: 'activities_get_property_activities',
	ACTIVITIES_GET_PROPERTY_REMINDERS: 'activities_get_property_reminders',
	ACTIVITIES_GET_USER_ACTIVITIES_BY_TIMEFRAME: 'activities_get_user_activities_by_timeframe',
	ACTIVITIES_GET_PROPERTY_ACTIVITIES_BY_TIMEFRAME: 'activities_get_property_activities_by_timeframe',
	ACTIVITIES_DELETE_ACTIVITY: 'activities_delete_activity',
	ACTIVITIES_GET_REMINDERS: 'activities_get_reminders',
	ACTIVITIES_GET_ACTIVITIES_BY_REQUEST: 'activities_get_activities_by_request',

	ACHIEVEMENTS_CREATE_ACHIEVEMENT: 'achievements_create_achievement',
	ACHIEVEMENTS_GET_ACHIEVEMENTS: 'achievements_get_achievements',
	ACHIEVEMENTS_GET_ACHIEVEMENT: 'achievements_get_achievement',
	ACHIEVEMENTS_UPDATE_ACHIEVEMENT: 'achievements_update_achievement',
	ACHIEVEMENTS_DELETE_ACHIEVEMENT: 'achievements_delete_achievement',

	UPLOAD_DOCUMENT: 'upload_document',
	GET_MEDIA: 'get_media',
	MEDIA_GET_PROPERTY_PDF: 'media_get_property_pdf'
};