import "./assets/reset.css";
import './assets/main-static.less';

import {createApp, markRaw} from 'vue';
import {createPinia} from 'pinia';

import App from './App.vue';
import router from './router';
import {i18n} from './i18n';

import resetStore from "@/stores/reset-store";
import '@mdi/font/css/materialdesignicons.css';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import {InferSeoMetaPlugin} from '@unhead/addons';

import VueUploadComponent from 'vue-upload-component';

// Vuetify
import 'vuetify/styles';
import {createVuetify} from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import {VDateInput} from 'vuetify/labs/VDateInput';
import {VNumberInput} from 'vuetify/labs/VNumberInput';
import {VTimePicker} from 'vuetify/labs/VTimePicker'

import {createVueI18nAdapter} from "vuetify/locale/adapters/vue-i18n";
import {useI18n} from "vue-i18n";

import * as Sentry from "@sentry/vue";
import {createHead} from "@unhead/vue";

//v-phone-input - https://github.com/paul-thebaud/v-phone-input
import 'flag-icons/css/flag-icons.min.css';
import 'v-phone-input/dist/v-phone-input.css';
import {createVPhoneInput} from 'v-phone-input';

const vuetify = createVuetify({
	components: {...components, VDateInput, VNumberInput, VTimePicker},
	directives,
	icons: {
		defaultSet: 'mdi' // This is already the default value - only for display purposes
	},
	locale: {
		adapter: createVueI18nAdapter({i18n, useI18n})
	}
});

const vPhoneInput = createVPhoneInput({
	countryIconMode: 'svg',
	enableSearchingCountry: true,
	countryLabel: '',
	singleLine: true,
	guessCountry: true
});

const app = createApp(App);

const pinia = createPinia();
pinia.use(resetStore);
pinia.use(piniaPluginPersistedstate);

pinia.use(({store}) => {
	store.router = markRaw(router);
});

app.use(pinia);
if(!import.meta.env.DEV){
	const isProd = import.meta.env.MODE === 'prod';
	const sentryIntegrations = [Sentry.browserTracingIntegration({router}),
		Sentry.replayIntegration(),
		Sentry.browserProfilingIntegration()
		// The following is all you need to enable canvas recording with Replay
		//per ora commento visto non funziona bene - riprovare in futuro
		//	Sentry.replayCanvasIntegration()
	];
	if(!isProd){
		sentryIntegrations.push(Sentry.feedbackIntegration({
			// Additional SDK configuration goes in here, https://docs.sentry.io/platforms/javascript/user-feedback/configuration/
			showBranding: false,
			isEmailRequired: true
		}));
	}
	Sentry.init({
		app,
		environment: import.meta.env.MODE,
		release: import.meta.env.VITE_SENTRY_PROJECT_NAME + "@" + __APP_VERSION__,
		dsn: import.meta.env.VITE_SENTRY_DSN,
		integrations: sentryIntegrations,
		// Performance Monitoring
		tracesSampleRate: .01, // Capture 1% of the transactions
		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["localhost", /^https:\/\/acasaapi-dev\.azurewebsites\.net\/api/, /^https:\/\/acasaapi-prod\.azurewebsites\.net\/api/], //TODO review
		// Session Replay
		replaysSessionSampleRate: .0005, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 0.91, // If you're not already sampling the entire session, change the sample rate to 91% when sampling sessions where errors occur.
		beforeSend(event, hint){ //https://docs.sentry.io/platforms/javascript/guides/vue/user-feedback/#integration
			// Check if it is an exception, and if so, show the report dialog
			if(!isProd && event.exception){
				Sentry.showReportDialog({eventId: event.event_id});
			}
			return event;
		}
	});
}

app.config.globalProperties.$filters = {
	formatCurrency(number){
		return Intl.NumberFormat(undefined, {style: "currency", currency: "EUR", useGrouping: "always"}).format(number);
	},
	formatDate(date, showTime = false){
		const options = {};
		if(showTime){
			options.hour = "numeric";
			options.minute = "numeric";
			options.year = "numeric";
			options.month = "numeric";
			options.day = "numeric";
			options.hour12 = false;
		}
		return Intl.DateTimeFormat(undefined, options).format(new Date(date));
	}
}

const head = createHead({
	plugins: [InferSeoMetaPlugin()]
});
app.use(head);

app.use(i18n);
app.use(router);
app.use(vuetify);
app.use(vPhoneInput);
app.component('file-upload', VueUploadComponent);

app.mount('#acasa');
