import {computed, ref} from 'vue';
import {defineStore} from 'pinia';
import {USER_FIELDS, USER_TYPES} from "@/utilities/enums";
import * as Sentry from "@sentry/vue";
import {EndpointsFactory} from "../utilities/endpoints.js";
import {DecodeResponseType, WorkerResponseCode} from "../utilities/workerUtilites.js";
import {useDomStore} from "./dom.js";
import {URLS_FACTORY} from "@/router/urls.js";

export const useUserStore = defineStore('user', () => {
	const currentUser = ref(null);
	const isLoggedIn = computed(() => !!currentUser.value);
	const fullName = computed(() => !currentUser.value ? '' : currentUser.value[USER_FIELDS.name] + " " + currentUser.value[USER_FIELDS.surname]);
	const initials = computed(() => {
		if(!currentUser.value){
			return '';
		}
		return currentUser.value[USER_FIELDS.name].substring(0, 1) + currentUser.value[USER_FIELDS.surname].substring(0, 1);
	});
	const currentToken = computed(() => currentUser.value ? currentUser.value[USER_FIELDS.token] : null);
	const isAdmin = computed(() => currentUser.value && (currentUser.value[USER_FIELDS.type] !== USER_TYPES.CLIENT));
	const isClient = computed(() => currentUser.value && (currentUser.value[USER_FIELDS.type] === USER_TYPES.CLIENT));
	const isSuperAdmin = computed(() => currentUser.value && (currentUser.value[USER_FIELDS.type] === USER_TYPES.ADMIN));

	function setUser(userData){
		if(!import.meta.env.DEV){
			Sentry.setUser({
				email: userData ? userData[USER_FIELDS.email] : null,
				id: userData ? userData[USER_FIELDS.id] : null
			});
		}
		currentUser.value = userData;
	}

	function logout(tokenIsExpired = false){
		if(!import.meta.env.DEV){
			Sentry.setUser(null);
		}
		return new Promise(resolve => {
			const $router = this.router;
			const onEnd = () => {
				useDomStore().reset();
				//useTextStore().reset();
				currentUser.value = null;
				$router.push(URLS_FACTORY.URLS_PATHS.ROOT).then(resolve)
			};

			if(tokenIsExpired || !currentUser.value){
				onEnd();
				return;
			}
			EndpointsFactory.execApiViaWorker({
				url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.LOGOUT, {user_id: currentUser.value[USER_FIELDS.id]}),
				token: currentToken.value,
				method: 'DELETE',
				decodeResponseType: DecodeResponseType.NOTHING
			}, WorkerResponseCode.LOGOUT, onEnd, onEnd);
		});
	}

	function updateUser(userData){
		currentUser.value = userData;
	}

	return {
		currentUser,
		isLoggedIn,
		setUser,
		logout,
		fullName,
		initials,
		currentToken,
		updateUser,
		isAdmin,
		isClient,
		isSuperAdmin
	}
}, {
	persist: true
});
