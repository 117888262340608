import {WorkerResponse} from "@/utilities/workerUtilites";
import {useAppStore} from "@/stores/app";
import {storeToRefs} from "pinia";
import {useUserStore} from "../stores/user.js";

const ENDPOINTS = {
	V1: 'v1',
	USERS: 'users',
	SIGNIN: 'signin',
	RESET: 'reset',
	CONTACTS: 'contacts',
	DOM: 'doms',
	CITIES: 'cities',
	LABELS: 'labels',
	PROPERTY: 'property',
	CATEGORIES: 'categories',
	ASSIGN: 'assign',
	COUNTRIES: 'countries',
	TYPOLOGIES: 'typologies',
	DIRECTIONS: 'directions',
	MEASUREMENTS: 'measurements',
	HEATING: 'heating',
	TYPES: 'types',
	POWERS: 'powers',
	CONDITIONS: 'conditions',
	PURPOSES: 'purposes',
	MEDIA: 'media',
	REGULATIONS: 'regulations',
	OUTDOORS: 'outdoors',
	BENEFITS: 'benefits',
	PROPERTIES: 'properties',
	REQUESTS: 'requests',
	ACTIVITIES: 'activities',
	CONTACT: 'contact',
	REQUEST: 'request',
	TIMEFRAMES: 'timeframes',
	LATEST: 'latest',
	ACHIEVEMENTS: 'achievements',
	FILTERS: 'filters',
	TIMEFRAME: 'timeframe',
	REMINDERS: 'reminders',
	STATUSES: 'statuses',
	NEWS: 'news',
	TAGS: 'tags',
	STATIC: 'static',
	CESSATION: 'cessation'
};

const QS_PARAMS = {
	LABEL_PREFIX: 'prefix='
};

export const QUERY_FIELDS = {
	order_by: 'orderBy',
	asc: 'asc',
	page: 'page',
	page_size: 'page_size',
	property_id: 'property_id',
	contact_id: 'contact_id',
	clients_included: 'is_client_included',
	cities: 'city_ids',
	price_min: 'price_min',
	price_max: 'price_max',
	owner_fullname: 'owner_name_surname',
	user_id: 'user_id',
	fullname: 'name_surname',
	title_address: 'title_address',
	latest_request: 'is_latest_request',
	enabled_only: 'is_enabled_only'
};

let worker;
const WorkerMessageObserver = {};

export class EndpointsFactory {
	static ENDPOINTS = {
		USERS: 'users_endpoint',
		LOGIN: 'login_endpoint',
		RESET_PWD: 'reset_endpoint',
		LOGOUT: 'logout_endpoint',

		CREATE_USER: 'create_user_endpoint',
		DELETE_USER: 'delete_user_endpoint',
		GET_USERS: 'get_users_endpoint',
		GET_USER: 'get_user_endpoint',
		UPDATE_USER: 'update_user_endpoint',

		CREATE_CONTACT: 'create_contact_endpoint',
		GET_CONTACTS: 'get_contacts_endpoint',
		GET_CONTACT: 'get_contact_endpoint',
		UPDATE_CONTACT: 'get_contact_endpoint',
		DELETE_CONTACT: 'delete_contact_endpoint',

		DOM_GET_CITIES: 'dom_get_cities_endpoint',
		DOM_GET_PROPERTY_CATEGORIES: 'dom_get_property_categories_endpoint',
		DOM_GET_PROPERTY_ASSIGNS: 'dom_get_property_assigns_endpoint',
		DOM_GET_COUNTRIES: 'dom_get_countries_endpoint',
		DOM_GET_PROPERTY_TYPOLOGIES: 'dom_get_property_typologies_endpoint',
		DOM_GET_DIRECTIONS: 'dom_get_directions_endpoint',
		DOM_GET_MEASUREMENTS: 'dom_get_measurements_endpoint',
		DOM_GET_HEATING_TYPES: 'dom_get_heating_types_endpoint',
		DOM_GET_HEATING_POWERS: 'dom_get_heating_powers_endpoint',
		DOM_GET_PROPERTY_CONDITIONS: 'dom_get_property_conditions_endpoint',
		DOM_GET_PROPERTY_PURPOSES: 'dom_get_property_purposes_endpoint',
		DOM_GET_PROPERTY_REGULATIONS: 'dom_get_property_regulations_endpoint',
		DOM_GET_PROPERTY_REGULATION_OPTION: 'dom_get_property_regulations_option_endpoint',
		DOM_GET_PROPERTY_OUTDOORS: 'dom_get_property_outdoors_endpoint',
		DOM_GET_PROPERTY_BENEFITS: 'dom_get_property_benefits_endpoint',
		DOM_GET_PROPERTY_BENEFITS_BY_CATEGORY: 'dom_get_property_benefits_by_category_endpoint',
		DOM_GET_ACTIVITIES: 'dom_get_activities_endpoint',
		DOM_GET_REQUEST_BENEFITS: 'dom_get_request_benefits_endpoint',
		DOM_GET_TIMEFRAMES: 'dom_get_timeframes_endpoint',
		DOM_GET_TIMEFRAMES_FILTERS: 'dom_get_timeframes_filters_endpoint',
		DOM_GET_NEWS_STATUSES: 'dom_get_news_statuses',
		DOM_GET_TAGS: 'dom_get_tags',

		GET_LABELS: 'get_labels_endpoint',

		UPLOAD_MEDIA: 'upload_media_endpoint',
		GET_MEDIA: 'get_media_endpoint',
		MEDIA_GET_PROPERTY_PDF: 'media_get_property_pdf_endpoint',

		PROPERTIES_GET_PROPERTIES: 'properties_get_properties_endpoint',
		PROPERTIES_CREATE_PROPERTY: 'properties_create_property_endpoint',
		PROPERTIES_SAVE_PROPERTY: 'properties_save_property_endpoint',
		PROPERTIES_GET_PROPERTY: 'properties_get_property_endpoint',
		PROPERTIES_GET_LATEST_PROPERTIES: 'properties_get_latest_properties_endpoint',
		PROPERTIES_GET_REQUEST_MATCHES: 'properties_get_request_matches_endpoint',

		REQUESTS_GET_REQUESTS: 'requests_get_requests_endpoint',
		REQUESTS_CREATE_REQUEST: 'requests_create_request_endpoint',
		REQUESTS_SAVE_REQUEST: 'requests_save_request_endpoint',
		REQUESTS_GET_REQUEST: 'requests_get_request_endpoint',

		ACTIVITIES_GET_ACTIVITIES: 'activities_get_activities_endpoint',
		ACTIVITIES_CREATE_ACTIVITY: 'activities_create_activity_endpoint',
		ACTIVITIES_GET_ACTIVITY: 'activities_get_activity_endpoint',
		ACTIVITIES_SAVE_ACTIVITY: 'activities_save_activity_endpoint',
		ACTIVITIES_GET_CONTACT_ACTIVITIES: 'activities_get_contact_activities_endpoint',
		ACTIVITIES_GET_PROPERTY_ACTIVITIES: 'activities_get_property_activities_endpoint',
		ACTIVITIES_GET_PROPERTY_REMINDERS: 'activities_get_property_reminders_endpoint',
		ACTIVITIES_GET_USER_ACTIVITIES_BY_TIMEFRAME: 'activities_get_user_activities_by_timeframe_endpoint',
		ACTIVITIES_GET_PROPERTY_ACTIVITIES_BY_TIMEFRAME: 'activities_get_property_activities_by_timeframe_endpoint',
		ACTIVITIES_DELETE_ACTIVITY: 'activities_delete_activity_endpoint',
		ACTIVITIES_GET_REMINDERS: 'activities_get_reminders',
		ACTIVITIES_GET_ACTIVITIES_BY_REQUEST: 'activities_get_activities_by_request_endpoint',

		ACHIEVEMENTS_GET_ACHIEVEMENTS: 'achievements_get_achievements_endpoint',
		ACHIEVEMENTS_CREATE_ACHIEVEMENT: 'achievements_create_achievement_endpoint',
		ACHIEVEMENTS_GET_ACHIEVEMENT: 'achievements_get_achievement_endpoint',
		ACHIEVEMENTS_SAVE_ACHIEVEMENT: 'achievements_save_achievement_endpoint',
		ACHIEVEMENTS_DELETE_ACHIEVEMENT: 'achievements_delete_achievement_endpoint'
	};

	static #API_endpoint = import.meta.env.VITE_API_ENDPOINT;

	static #users_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.USERS;
	static #contacts_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.CONTACTS;
	static #dom_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.DOM;
	static #labels_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.LABELS;
	static #media_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.MEDIA;
	static #properties_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.PROPERTIES;
	static #requests_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.REQUESTS;
	static #activities_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.ACTIVITIES;
	static #achievements_endpoint = ENDPOINTS.V1 + "/" + ENDPOINTS.ACHIEVEMENTS;

	static initWorker(newWorker){
		worker = newWorker;
		worker.addEventListener('message', handleWorkerMessage);
	}

	//answerCode identifica la risposta alla chiamata api. Così l'observer sa cosa aspettare e non risponde a una chiamata a caso.
	static execApiViaWorker({
		                        url,
		                        token,
		                        contentType,
		                        method,
		                        body,
		                        formData,
		                        decodeResponseType
	                        }, answerCode, successCallback, failCallback){
		if(!worker){
			throw 'WebWorker not initialised';
		}
		if(answerCode){
			if(!WorkerMessageObserver[answerCode]){
				WorkerMessageObserver[answerCode] = [];
			}
			WorkerMessageObserver[answerCode].push({
				onSuccess: successCallback,
				onFail: failCallback
			});
		}
		worker.postMessage({
			url: url,
			token: token,
			contentType: contentType,
			method: method,
			responseCode: answerCode,
			body: body,
			formData: formData,
			decodeResponseType: decodeResponseType
		});
	}

	static fetchGlobalHeaders(token = null, contentType = 'application/json'){
		const headers = new Headers();
		if(contentType){
			headers.append('Content-Type', contentType);
		}
		headers.append('Accept', 'application/json');
		if(token){
			headers.append('Authorization', `Bearer ${token}`)
		}
		return headers;
	}

	static getEndpoint(endpoint_name, {
		query_params,
		user_id,
		contact_id,
		language_code,
		property_category_id,
		labels_prefix,
		media_id,
		regulation_root,
		benefit_category_id,
		property_id,
		activity_id,
		request_id,
		achievement_id,
		timeframe_filter_id
	} = {}) {
		let url;
		switch (endpoint_name){
			case this.ENDPOINTS.LOGIN:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.SIGNIN;
			case this.ENDPOINTS.LOGOUT:
				if(!user_id){
					throw 'Missing query_user_id param';
				}
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.SIGNIN + "/" + user_id;
			case this.ENDPOINTS.RESET_PWD:
				return this.#API_endpoint + this.#users_endpoint + "/" + ENDPOINTS.RESET;

			case this.ENDPOINTS.CREATE_USER:
				return this.#API_endpoint + this.#users_endpoint;
			case this.ENDPOINTS.GET_USERS:
				url = this.#API_endpoint + this.#users_endpoint;
				if(query_params){
					url += "?" + new URLSearchParams(query_params);
				}
				return url;
			case this.ENDPOINTS.GET_USER:
			case this.ENDPOINTS.DELETE_USER:
			case this.ENDPOINTS.UPDATE_USER:
				if(!user_id){
					throw 'Missing query_user_id param';
				}
				return this.#API_endpoint + this.#users_endpoint + "/" + user_id;

			case this.ENDPOINTS.CREATE_CONTACT:
				return this.#API_endpoint + this.#contacts_endpoint;
			case this.ENDPOINTS.GET_CONTACTS:
				url = this.#API_endpoint + this.#contacts_endpoint;
				if(query_params){
					url += "?" + new URLSearchParams(query_params);
				}
				return url;
			case this.ENDPOINTS.UPDATE_CONTACT:
			case this.ENDPOINTS.GET_CONTACT:
			case this.ENDPOINTS.DELETE_CONTACT:
				if(!contact_id){
					throw 'Missing query_contact_id param';
				}
				return this.#API_endpoint + this.#contacts_endpoint + "/" + contact_id;

			case this.ENDPOINTS.GET_LABELS:
				if(!language_code){
					throw 'Missing language_code param';
				}
				if(!labels_prefix){
					throw 'Missing labels_prefix param';
				}
				return this.#API_endpoint + this.#labels_endpoint + "/" + language_code + "?" + QS_PARAMS.LABEL_PREFIX + labels_prefix;

			case this.ENDPOINTS.DOM_GET_CITIES:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.CITIES;
			case this.ENDPOINTS.DOM_GET_PROPERTY_CATEGORIES:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.PROPERTY + "/" + ENDPOINTS.CATEGORIES;
			case this.ENDPOINTS.DOM_GET_PROPERTY_TYPOLOGIES:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.PROPERTY + "/" + ENDPOINTS.TYPOLOGIES + (property_category_id ? "/" + property_category_id : '');
			case this.ENDPOINTS.DOM_GET_PROPERTY_ASSIGNS:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.PROPERTY + "/" + ENDPOINTS.ASSIGN;
			case this.ENDPOINTS.DOM_GET_COUNTRIES:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.COUNTRIES;
			case this.ENDPOINTS.DOM_GET_DIRECTIONS:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.DIRECTIONS;
			case this.ENDPOINTS.DOM_GET_MEASUREMENTS:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.MEASUREMENTS;
			case this.ENDPOINTS.DOM_GET_HEATING_TYPES:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.HEATING + "/" + ENDPOINTS.TYPES;
			case this.ENDPOINTS.DOM_GET_HEATING_POWERS:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.HEATING + "/" + ENDPOINTS.POWERS;
			case this.ENDPOINTS.DOM_GET_PROPERTY_CONDITIONS:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.PROPERTY + "/" + ENDPOINTS.CONDITIONS;
			case this.ENDPOINTS.DOM_GET_PROPERTY_PURPOSES:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.PROPERTY + "/" + ENDPOINTS.PURPOSES;
			case this.ENDPOINTS.DOM_GET_PROPERTY_REGULATIONS:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.PROPERTY + "/" + ENDPOINTS.REGULATIONS;
			case this.ENDPOINTS.DOM_GET_PROPERTY_REGULATION_OPTION:
				if(!regulation_root){
					throw 'Missing regulation_root param';
				}
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.PROPERTY + "/" + ENDPOINTS.REGULATIONS + "/" + regulation_root;
			case this.ENDPOINTS.DOM_GET_PROPERTY_OUTDOORS:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.PROPERTY + "/" + ENDPOINTS.OUTDOORS;
			case this.ENDPOINTS.DOM_GET_PROPERTY_BENEFITS:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.PROPERTY + "/" + ENDPOINTS.BENEFITS;
			case this.ENDPOINTS.DOM_GET_PROPERTY_BENEFITS_BY_CATEGORY:
				if(!benefit_category_id){
					throw 'Missing benefit_category_id param';
				}
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.PROPERTY + "/" + ENDPOINTS.BENEFITS + "/" + benefit_category_id;
			case this.ENDPOINTS.DOM_GET_ACTIVITIES:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.ACTIVITIES;
			case this.ENDPOINTS.DOM_GET_REQUEST_BENEFITS:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.REQUEST + "/" + ENDPOINTS.BENEFITS;
			case this.ENDPOINTS.DOM_GET_TIMEFRAMES:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.TIMEFRAMES;
			case this.ENDPOINTS.DOM_GET_TIMEFRAMES_FILTERS:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.TIMEFRAMES + "/" + ENDPOINTS.FILTERS;
			case this.ENDPOINTS.DOM_GET_NEWS_STATUSES:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.STATUSES + "/" + ENDPOINTS.NEWS;
			case this.ENDPOINTS.DOM_GET_TAGS:
				return this.#API_endpoint + this.#dom_endpoint + "/" + ENDPOINTS.TAGS;

			case this.ENDPOINTS.UPLOAD_MEDIA:
				return this.#API_endpoint + this.#media_endpoint + (media_id ? "/" + media_id : '');
			case this.ENDPOINTS.GET_MEDIA:
				if(!media_id){
					throw 'Missing media_id param';
				}
				return this.#API_endpoint + this.#media_endpoint + "/" + media_id;
			case this.ENDPOINTS.MEDIA_GET_PROPERTY_PDF:
				return this.#API_endpoint + this.#media_endpoint + "/" + ENDPOINTS.STATIC + "/" + ENDPOINTS.CESSATION;

			case this.ENDPOINTS.PROPERTIES_GET_PROPERTIES:
				url = this.#API_endpoint + this.#properties_endpoint;
				if(query_params){
					url += "?" + new URLSearchParams(query_params);
				}
				return url;
			case this.ENDPOINTS.PROPERTIES_CREATE_PROPERTY:
				return this.#API_endpoint + this.#properties_endpoint;
			case this.ENDPOINTS.PROPERTIES_SAVE_PROPERTY:
			case this.ENDPOINTS.PROPERTIES_GET_PROPERTY:
				if(!property_id){
					throw 'Missing property_id param';
				}
				return this.#API_endpoint + this.#properties_endpoint + "/" + property_id;
			case this.ENDPOINTS.PROPERTIES_GET_LATEST_PROPERTIES:
				return this.#API_endpoint + this.#properties_endpoint + "/" + ENDPOINTS.LATEST;
			case this.ENDPOINTS.PROPERTIES_GET_REQUEST_MATCHES:
				if(!request_id){
					throw 'Missing request_id param';
				}
				return this.#API_endpoint + this.#properties_endpoint + "/" + ENDPOINTS.REQUESTS + "/" + request_id;

			case this.ENDPOINTS.REQUESTS_GET_REQUESTS:
				url = this.#API_endpoint + this.#requests_endpoint;
				if(query_params){
					url += "?" + new URLSearchParams(query_params);
				}
				return url;
			case this.ENDPOINTS.REQUESTS_CREATE_REQUEST:
				return this.#API_endpoint + this.#requests_endpoint;
			case this.ENDPOINTS.REQUESTS_SAVE_REQUEST:
			case this.ENDPOINTS.REQUESTS_GET_REQUEST:
				if(!request_id){
					throw 'Missing request_id param';
				}
				return this.#API_endpoint + this.#requests_endpoint + "/" + request_id;

			case this.ENDPOINTS.ACTIVITIES_GET_ACTIVITIES:
				url = this.#API_endpoint + this.#activities_endpoint;
				if(query_params){
					url += "?" + new URLSearchParams(query_params);
				}
				return url;
			case this.ENDPOINTS.ACTIVITIES_CREATE_ACTIVITY:
				return this.#API_endpoint + this.#activities_endpoint;
			case this.ENDPOINTS.ACTIVITIES_GET_ACTIVITY:
			case this.ENDPOINTS.ACTIVITIES_DELETE_ACTIVITY:
			case this.ENDPOINTS.ACTIVITIES_SAVE_ACTIVITY:
				if(!activity_id){
					throw 'Missing activity_id param';
				}
				return this.#API_endpoint + this.#activities_endpoint + "/" + activity_id;
			case this.ENDPOINTS.ACTIVITIES_GET_CONTACT_ACTIVITIES:
				if(!contact_id){
					throw 'Missing contact_id param';
				}
				url = this.#API_endpoint + this.#activities_endpoint;
				url += "?" + new URLSearchParams({[QUERY_FIELDS.contact_id]: contact_id});
				if(query_params){
					url += "&" + new URLSearchParams(query_params);
				}
				return url;
			case this.ENDPOINTS.ACTIVITIES_GET_PROPERTY_ACTIVITIES:
				if(!property_id){
					throw 'Missing property_id param';
				}
				url = this.#API_endpoint + this.#activities_endpoint;
				url += "?" + new URLSearchParams({[QUERY_FIELDS.property_id]: property_id});
				if(query_params){
					url += "&" + new URLSearchParams(query_params);
				}
				return url;
			case this.ENDPOINTS.ACTIVITIES_GET_PROPERTY_REMINDERS:
				if(!property_id){
					throw 'Missing property_id param';
				}
				url = this.#API_endpoint + this.#activities_endpoint + "/" + ENDPOINTS.PROPERTIES + "/" + property_id + "/" + ENDPOINTS.REMINDERS;
				if(query_params){
					url += "?" + new URLSearchParams(query_params);
				}
				return url;
			case this.ENDPOINTS.ACTIVITIES_GET_USER_ACTIVITIES_BY_TIMEFRAME:
				if(!user_id){
					throw 'Missing user_id param';
				}
				if(!timeframe_filter_id){
					throw 'Missing timeframe_filter_id param';
				}
				url = this.#API_endpoint + this.#activities_endpoint + "/" + ENDPOINTS.USERS + "/" + user_id + "/" + ENDPOINTS.TIMEFRAME + "/" + timeframe_filter_id;
				if(query_params){
					url += "&" + new URLSearchParams(query_params);
				}
				return url;
			case this.ENDPOINTS.ACTIVITIES_GET_PROPERTY_ACTIVITIES_BY_TIMEFRAME:
				if(!property_id){
					throw 'Missing property_id param';
				}
				if(!timeframe_filter_id){
					throw 'Missing timeframe_filter_id param';
				}
				url = this.#API_endpoint + this.#activities_endpoint + "/" + ENDPOINTS.PROPERTIES + "/" + property_id + "/" + ENDPOINTS.TIMEFRAME + "/" + timeframe_filter_id;
				if(query_params){
					url += "&" + new URLSearchParams(query_params);
				}
				return url;
			case this.ENDPOINTS.ACTIVITIES_GET_REMINDERS:
				url = this.#API_endpoint + this.#activities_endpoint + "/" + ENDPOINTS.REMINDERS;
				if(query_params){
					url += "?" + new URLSearchParams(query_params);
				}
				return url;
			case this.ENDPOINTS.ACTIVITIES_GET_ACTIVITIES_BY_REQUEST:
				if(!request_id){
					throw 'Missing request_id param';
				}
				return this.#API_endpoint + this.#activities_endpoint + "/" + ENDPOINTS.REQUESTS + "/" + request_id;

			case this.ENDPOINTS.ACHIEVEMENTS_GET_ACHIEVEMENTS:
			case this.ENDPOINTS.ACHIEVEMENTS_CREATE_ACHIEVEMENT:
				return this.#API_endpoint + this.#achievements_endpoint;
			case this.ENDPOINTS.ACHIEVEMENTS_SAVE_ACHIEVEMENT:
			case this.ENDPOINTS.ACHIEVEMENTS_GET_ACHIEVEMENT:
			case this.ENDPOINTS.ACHIEVEMENTS_DELETE_ACHIEVEMENT:
				if(!achievement_id){
					throw 'Missing achievement_id param';
				}
				return this.#API_endpoint + this.#achievements_endpoint + "/" + achievement_id;
		}
	}
}

function handleWorkerMessage({data: msg}){
	const observers = WorkerMessageObserver[msg.code];

	const observerError = result => {
		const statusObj = result ? result.status ? result : result.result : result;
		if(statusObj){
			for(let i = observers.length - 1; i >= 0; --i){
				const o = observers[i];
				if(o.onFail){
					if(o.onFail(result) !== false){
						observers.splice(i, 1);
					}
				}
			}
		}
	};

	const observerSuccess = result => {
		for(let i = observers.length - 1; i >= 0; --i){
			const o = observers[i];
			if(o.onSuccess){
				if(o.onSuccess(result) !== false){
					observers.splice(i, 1);
				}
				continue;
			}
			observers.splice(i, 1);
		}
	};

	const result = msg.response;
	switch (msg.status){
		case WorkerResponse.ERROR:{
			console.error(msg);
			if(msg.http_code === 401 && msg.response === "Wrong token"){
				//const {t: $t} = useI18n(); //todo qui non gli piace
				const userStore = useUserStore();
				const appStore = useAppStore();
				const {expiredLogout} = storeToRefs(appStore);
				if(expiredLogout.value){ //errore già notificato; inutile ri-notificarlo
					return;
				}
				expiredLogout.value = true;
				appStore.showError({body: 'La sessione è scaduta. Fai nuovamente login per continuare ad operare.'}); //todo $t('global.session_expired')
				userStore.logout(true).then();
				return;
			}
			observerError(msg);
			break;
		}
		case WorkerResponse.SUCCESS:{
			observerSuccess({result: result, contentType: msg.response_type});
			break;
		}
	}
}