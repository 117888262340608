import {createI18n} from "vue-i18n";
import messages from "@intlify/unplugin-vue-i18n/messages";
import {setLocale} from "yup";

export const defaultLocale = "it";

export const i18n = createI18n({
	legacy: false,
	globalInjection: true,
	locale: defaultLocale,
	fallbackLocale: defaultLocale,
	availableLocales: [defaultLocale, "en"],
	messages: messages,
	warnHtmlInMessage: false,
	warnHtmlMessage: false
});

export const yupSetLocale = $t => setLocale({
	// use constant translation keys for messages without values
	mixed: {
		default: ({path}) => $t('form_errors.invalid', {path: path}),
		required: ({path}) => $t('form_errors.required', {path: path}),
		notType: ({path}) => $t('form_errors.invalid', {path: path}),
		oneOf: ({values, path}) => $t('form_errors.one_of', {path: path, values: values})
	},
	string: {
		email: ({path}) => $t('form_errors.email', {path: path}),
		min: ({min, path}) => $t('form_errors.too_short', {min: min, path: path}),
		max: ({max, path}) => $t('form_errors.too_long', {max: max, path: path}),
		length: ({length, path}) => $t('form_errors.wrong_length', {val: length, path: path}),
		url: ({path}) => $t('form_errors.url', {path: path})
	},
	number: {
		min: ({min, path}) => $t('form_errors.too_small', {min: min, path: path}),
		max: ({max, path}) => $t('form_errors.too_big', {max: max, path: path}),
		moreThan: ({more, path}) => $t('form_errors.must_be_greater_than', {value: more ? more : 0, path: path}),
		lessThan: ({less, path}) => $t('form_errors.must_be_less_than', {value: less ? less : 0, path: path})
	},
	date: {
		max: ({max, path}) => $t('form_errors.date_too_early', {
			max: max.toLocaleString("it-It", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit"
			}), path: path
		}),
		min: ({min, path}) => $t('form_errors.date_too_late', {
			min: min.toLocaleString("it-It", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit"
			}), path: path
		}),
	},
	array: {
		min: ({min, path}) => $t('form_errors.array_too_short', {min: min, path: path})
	}
});