<script setup>
import {URLS_FACTORY} from "@/router/urls.js";
import {useUserStore} from "@/stores/user.js";
import {storeToRefs} from "pinia";

const notifications = 1; //TODO

const userStore = useUserStore();
const {currentToken, isAdmin} = storeToRefs(userStore);
</script>

<template>
  <v-container>
    <div class="campanella" :class="{'ring':notifications}"
         @click="$router.push(isAdmin ? URLS_FACTORY.getURL(URLS_FACTORY.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_NOTIICATIONS) : URLS_FACTORY.getURL(URLS_FACTORY.URLS_FULL_PATHS.FULL_DASHBOARD_USER_NOTIICATIONS))">
      <v-icon color="white" icon="mdi-bell-outline" size="x-small"></v-icon>
      <div class="notification" ></div>
    </div>
  </v-container>
</template>

<style lang="less" scoped>
@size-icon:31px;
.campanella {
  .set-border-radius(@size-icon);
  border: 1px solid @white;
  width: @size-icon;
  height: @size-icon;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 30px;
  cursor: pointer;

  &.ring {
    .notification {
      display: block;
    }
  }
}

.notification{
  display: none;
  .set-border-radius(14px);
  width: 14px;
  height: 14px;
  background: @error;
  position: absolute;
  top: -5px;
  right: -5px;
}
</style>