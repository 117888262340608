<script setup>
import {computed, ref} from "vue";

const model = defineModel();

defineProps({
  errorMessages: {
    type: [String, Array]
  },
  label: {
    type: String,
    default: ''
  }
});

const type = computed(() => reveal.value ? 'text' : 'password');
const icon = computed(() => reveal.value ? 'mdi-eye-off' : 'mdi-eye');
const reveal = ref(false);
</script>

<template>
  <div class="show-pwd-inpt">
    <v-text-field v-model="model" :error-messages="errorMessages" :label="label" :type="type"></v-text-field>
    <v-btn :icon="icon" tabindex="-1" @click="reveal = !reveal"></v-btn>
  </div>
</template>

<style lang="less" scoped></style>