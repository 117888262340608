<script setup>
import {useUserStore} from "@/stores/user";
import {storeToRefs} from "pinia";
import {USER_FIELDS} from "../utilities/enums.js";
import {useAppStore} from "@/stores/app.js";
import {URLS_FACTORY} from "@/router/urls.js";
import {computed} from "vue";

defineProps({
  size: {
    type: [Number, String],
    default: 63
  }
});

const userStore = useUserStore();
const appStore = useAppStore();
const {initials, fullName, currentUser, isClient} = storeToRefs(userStore);

const editProfileLink = computed(() => {
  if(!isClient.value){
    return URLS_FACTORY.getURL(URLS_FACTORY.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_USER, {user_id: currentUser.value[USER_FIELDS.id]});
  }
  return URLS_FACTORY.getURL(URLS_FACTORY.URLS_FULL_PATHS.FULL_DASHBOARD_USER_PROFILE, {user_id: currentUser.value[USER_FIELDS.id]});
});

const pageLabelPrefix = 'dashboard.global.';

function logout(){
  appStore.showSpinner();
  userStore.logout().then(() => {
    appStore.hideSpinner();
  });
}
</script>

<template>
  <v-container class="wrap-user-avatar" fluid>
    <v-row justify="center">
      <v-menu min-width="200px" rounded>
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props">
            <v-avatar :size="size" class="user-avatar">
              <span class="text-letter">{{ initials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <div class="mx-auto text-center">
              <v-avatar>
                <span class="text-h5">{{ initials }}</span>
              </v-avatar>
              <h3>{{ fullName }}</h3>
              <p class="text-caption mt-1">
                {{ currentUser[USER_FIELDS.email] }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn
                  :to="editProfileLink"
                  rounded variant="text">
                {{ $t(pageLabelPrefix + "edit_account") }}
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn rounded variant="text" @click="logout">
                {{ $t(pageLabelPrefix + "logout") }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-row>
  </v-container>
</template>

<style lang="less" scoped>
@size-user-avatar:63px;
.wrap-user-avatar{
  height: @size-user-avatar;
  width: @size-user-avatar;
  min-width: @size-user-avatar;
}

.v-avatar{
  font-weight: 400;
  font-size: 25px;
  background: @secondary-color !important;
  color: @white !important;
  cursor: pointer;
  text-transform: uppercase;
}

//stile icona esterna
.user-avatar{
  .text-letter{
    font-size: 40px;
    font-weight: 200;
  }
}
</style>