import {createRouter, createWebHistory} from 'vue-router';
import HomeView from '../views/public/LoginView.vue';
import {useUserStore} from "@/stores/user.js";
import {storeToRefs} from "pinia";
import {URLS_FACTORY} from "./urls.js";

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	scrollBehavior(to, from, savedPosition){
		// more info https://router.vuejs.org/guide/advanced/scroll-behavior.html
		if(to.hash){
			return {el: to.hash, behavior: 'smooth'}
		}
		// always scroll to top
		return {top: 0}
	},
	//NOTA: se aggiungi una pagina pubblica, ricordati di aggiungerne il path in pages.js così che vada in sitemap!
	routes: [
		{
			path: URLS_FACTORY.URLS_PATHS.ROOT,
			name: 'home',
			alias: URLS_FACTORY.URLS_PATHS.HOME,
			meta: {requiresGuest: true},
			component: HomeView
		},
		{
			path: URLS_FACTORY.URLS_PATHS.FORGOT_PASSWORD,
			name: 'forgotpassword',
			meta: {requiresGuest: true},
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import('../views/public/ForgotPasswordView.vue')
		},
		{
			path: URLS_FACTORY.URLS_PATHS.DASHBOARD,
			// only authenticated users can create posts
			meta: {requiresAuth: true},
			children: [
				{
					path: URLS_FACTORY.URLS_PATHS.DASHBOARD__USER,
					beforeEnter: (to, from, next) => {
						const userStore = useUserStore();
						//sappiamo già è loggato dai controlli su /dashboard
						const {isAdmin} = storeToRefs(userStore);
						if(isAdmin.value){
							next(URLS_FACTORY.URLS_PATHS.FULL_DASHBOARD_ADMIN);
						}
						else{
							next();
						}
					},
					component: () => import('../views/dashboards/user/DashboardView.vue'),
					children: [
						{
							path: 'home',
							alias: '',
							name: 'userdashboard',
							component: () => import('../views/dashboards/user/HomeView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__NOTIICATIONS,
							name: 'user_notifications',
							component: () => import('../views/dashboards/admin/NotificationsView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__USER__PROFILE + ':id',
							name: 'user_profile',
							props: true,
							component: () => import('../views/dashboards/UserView.vue')
						}
					]
				},
				{
					path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN,
					beforeEnter: (to, from, next) => {
						const userStore = useUserStore();
						//sappiamo già è loggato dai controlli su /dashboard
						const {isAdmin} = storeToRefs(userStore);
						if(!isAdmin.value){
							next(URLS_FACTORY.URLS_PATHS.ROOT);
						}
						else{
							next();
						}
					},
					component: () => import('../views/dashboards/admin/DashboardView.vue'),
					children: [
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__HOME,
							name: 'admin_dashboard',
							component: () => import('../views/dashboards/admin/HomeView.vue'),
							alias: ''
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__USERS,
							name: 'users',
							component: () => import('../views/dashboards/admin/UsersView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__USER + ':id',
							name: 'user',
							props: true,
							component: () => import('../views/dashboards/UserView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__CONTACTS,
							name: 'contacts',
							component: () => import('../views/dashboards/admin/ContactsView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__CONTACT + ':id',
							name: 'contact',
							props: true,
							component: () => import('../views/dashboards/admin/ContactView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__ACTIVITIES,
							name: 'activities',
							component: () => import('../views/dashboards/admin/ActivitiesView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__ACTIVITY + ':id',
							name: 'activity',
							props: true,
							component: () => import('../views/dashboards/admin/ActivityView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__ACTIVITIES + "/" + URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__USER,
							name: 'activities_user',
							component: () => import('../views/dashboards/admin/ActivitiesUserView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__ACTIVITIES + "/" + URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__PROPERTY + "/" + ':propertyId',
							name: 'activities_property',
							props: true,
							component: () => import('../views/dashboards/admin/ActivitiesPropertyView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__REQUESTS,
							name: 'requests',
							component: () => import('../views/dashboards/admin/RequestsView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__TARGETS,
							name: 'targets',
							component: () => import('../views/dashboards/admin/TargetsView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__TARGET + ':id',
							name: 'target',
							props: true,
							component: () => import('../views/dashboards/admin/TargetView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__REMINDERS,
							name: 'reminders',
							component: () => import('../views/dashboards/admin/RemindersView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__REMINDER + ':id',
							name: 'reminder',
							props: true,
							component: () => import('../views/dashboards/admin/ReminderView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__NOTIICATIONS,
							name: 'admin_notifications',
							component: () => import('../views/dashboards/admin/NotificationsView.vue'),
							alias: ''
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__PROPERTIES,
							name: 'buildings',
							component: () => import('../views/dashboards/admin/PropertiesView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__PROPERTY + ':id',
							name: 'building',
							props: true,
							component: () => import('../views/dashboards/admin/PropertyView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__REQUEST + ':id',
							name: 'request',
							props: true,
							component: () => import('../views/dashboards/admin/RequestView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__REQUEST + ':id' + "/" + URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__MATCHING,
							name: 'request_matching',
							props: true,
							component: () => import('../views/dashboards/admin/RequestMatchingView.vue')
						},
						{
							path: URLS_FACTORY.URLS_PATHS.DASHBOARD__ADMIN__SEARCH,
							name: 'search',
							component: () => import('../views/dashboards/admin/SearchView.vue')
						}
					]
				}
			]
		},
		// everything else will match everything and put it under `$route.params.pathMatch` (we don't use a specific 404 page, but we redirect to homepage)
		{path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('../views/public/404View.vue')}
	]
});

router.beforeEach((to, from) => {
	const userStore = useUserStore();
	// instead of having to check every route record with
	// to.matched.some(record => record.meta.requiresAuth)
	if(to.meta.requiresAuth && (!userStore.isLoggedIn)){
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		return {
			path: URLS_FACTORY.URLS_PATHS.ROOT,
			// save the location we were at to come back later
			query: {redirect: to.fullPath}
		};
	}
	if(to.meta.requiresGuest && userStore.isLoggedIn){
		// this route requires not auth, check if logged in
		// if yes, redirect to home page.
		if(userStore.isAdmin){
			return {
				path: URLS_FACTORY.getURL(URLS_FACTORY.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN)
			};
		}
		return {
			path: URLS_FACTORY.getURL(URLS_FACTORY.URLS_FULL_PATHS.FULL_DASHBOARD_USER)
		};
	}
});

export default router;
