<script setup>
import * as yup from "yup";
import {useField, useForm} from "vee-validate";
import {useI18n} from "vue-i18n";
import {useAppStore} from "@/stores/app.js";
import {useRoute, useRouter} from "vue-router";
import {yupSetLocale} from "../../i18n";
import InputShowPassword from "@/components/inputs/InputShowPassword.vue";
import {useHead} from "@unhead/vue";
import {USER_FIELDS} from "@/utilities/enums.js";
import {useUserStore} from "@/stores/user.js";
import {storeToRefs} from "pinia";
import {URLS_FACTORY} from "@/router/urls.js";
import {EndpointsFactory} from "@/utilities/endpoints.js";
import {WorkerResponseCode} from "@/utilities/workerUtilites.js";

useHead({
  title: import.meta.env.VITE_META_SITE_TITLE,
  meta: [{
    name: 'description',
    content: import.meta.env.VITE_META_LOGIN_DESCRIPTION
  }]
});

const {t: $t} = useI18n();
yupSetLocale($t);
const pageLabelPrefix = 'login.';

const loginSchema = yup.object({
  [USER_FIELDS.email]: yup.string().email().min(3).lowercase().required().label($t(pageLabelPrefix + 'email')),
  [USER_FIELDS.password]: yup.string().required().label($t(pageLabelPrefix + 'password')),
  [USER_FIELDS.remember]: yup.boolean().label($t(pageLabelPrefix + 'remember_me'))
});

const {handleSubmit, handleReset} = useForm({
  validationSchema: loginSchema,
  keepValuesOnUnmount: true
});

const loginData = {
  email: useField(USER_FIELDS.email),
  password: useField(USER_FIELDS.password),
  remember: useField(USER_FIELDS.remember)
};

const appStore = useAppStore();
const $router = useRouter();
const $route = useRoute();
const userStore = useUserStore();
const {isAdmin} = storeToRefs(userStore);

const submit = handleSubmit(values => {
  appStore.showSpinner();

  EndpointsFactory.execApiViaWorker({
        url: EndpointsFactory.getEndpoint(EndpointsFactory.ENDPOINTS.LOGIN),
        method: 'POST',
        body: JSON.stringify(values)
      },
      WorkerResponseCode.LOGIN, ({result: activityData}) => {
        userStore.setUser(activityData.user);

        if($route.query.redirect){
          $router.push($route.query.redirect);
        }
        else{
          if(isAdmin.value){
            $router.push(URLS_FACTORY.getURL(URLS_FACTORY.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN));
          }
          else{
            $router.push(URLS_FACTORY.getURL(URLS_FACTORY.URLS_FULL_PATHS.FULL_DASHBOARD_USER));
          }
        }
        appStore.hideSpinner();
      }, ({http_code, result}) => {
        if(http_code === 401){
          appStore.showError({body: $t(pageLabelPrefix + 'invalid_credentials')});
          return;
        }
        console.warn(result);
        appStore.showError();
      });
});
</script>

<template>
  <div class="main-log">
    <h3 class="title-log">{{ $t(pageLabelPrefix + "login_title") }}</h3>

    <v-form class="form-reg" @submit.prevent="submit">
      <div>
        <v-text-field v-model.trim="loginData.email.value.value" :error-messages="loginData.email.errorMessage.value"
                      :label="$t(pageLabelPrefix + 'email')" class="inpt-gen" inputmode="email" required
                      type="email"></v-text-field>
        <input-show-password v-model.trim="loginData.password.value.value"
                             :error-messages="loginData.password.errorMessage.value"
                             :label="$t(pageLabelPrefix + 'password')" class="inpt-gen"></input-show-password>
        <v-checkbox v-model="loginData.remember.value.value" :error-messages="loginData.remember.errorMessage.value"
                    :label="$t(pageLabelPrefix + 'remember_me')"
                    class="inpt-check"></v-checkbox>

      </div>
      <div class="btn-wrapper">
        <v-btn class="m-btn big-green" flat type="submit">{{ $t(pageLabelPrefix + 'login') }}</v-btn>
      </div>

      <div class="lost-pas">
        <router-link :to="URLS_FACTORY.URLS_PATHS.FORGOT_PASSWORD">{{
            $t(pageLabelPrefix + 'forgot_password')
          }}
        </router-link>
      </div>
    </v-form>
  </div>
</template>

<style lang="less" scoped>
.lost-pas, .log-reg {
  margin-bottom: 100px;
  text-align: center;
  color: @font-color;
  font-size: 20px;

  a {
    text-decoration: none;
    color: @font-color;
    font-weight: 600;
  }
}

//bottone login
.big-green{
  width: 255px;
}

.otp-code {
  .inpt-gen {
    margin-bottom: 20px;
  }
}

.log-reg {
  margin-top: 20px;
}

.num-call {
  text-align: center;
  display: block;
  margin-bottom: 20px;
  color: @font-color;
  font-size: 20px;
}

//bottoni
.btn-wrapper {
  text-align: center;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  column-gap: 40px;
  margin-bottom: 40px;
}

.form-reg {
  margin: auto;
}

.mobile-main {
  .lost-pas {
    margin-bottom: 50px;
  }
}
</style>