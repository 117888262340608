<template>
  <div class="wrapper-spinner">
    <v-progress-circular :size="79" :width="7" color="#00000" indeterminate></v-progress-circular>
  </div>
</template>

<script setup>
</script>

<style lang="less" scoped>
.wrapper-spinner {
  top: 0;
  z-index: 911;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}
//
//.text-modal {
//  margin-bottom: 50px;
//}
//
//.loadingContacts-dot {
//  height: 60px;
//  border-radius: 30px;
//  border: 5px solid @white;
//  text-align: left;
//  padding: 6px;
//  position: relative;
//
//  &:after {
//    content: '';
//    display: inline-block;
//    width: 38px;
//    height: 38px;
//    .set-border-radius(38px);
//    background: @white;
//
//    position: relative;
//    animation: mymove 1.4s;
//    animation-iteration-count: infinite;
//    animation-timing-function: ease-in-out;
//  }
//}
//
//@keyframes mymove {
//  0% {
//    left: 0;
//  }
//  50% {
//    left: 91%
//  }
//  100% {
//    left: 0;
//  }
//}
</style>