<template>
  <div>
    <v-app-bar class="top-bar" flat>
      <v-app-bar-title>
        <template v-slot:text>
          <v-img src="@/assets/img/logo.png" @click="homeNavigation" class="logo">
            <template v-slot:placeholder>
              <image-loader></image-loader>
            </template>
          </v-img>
        </template>
      </v-app-bar-title>
      <div v-if="isLoggedIn && !isClient" class="container-search">
        <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" type="search"></v-text-field>
      </div>
      <div class="wrap-top-action">
        <notification-box v-if="isLoggedIn"></notification-box>
        <user-avatar v-if="isLoggedIn"></user-avatar>
        <router-link v-else :to="URLS_FACTORY.URLS_PATHS.ROOT" class="link-login">{{
            $t('global.signin')
          }}
        </router-link>
      </div>
    </v-app-bar>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import ImageLoader from "@/components/ImageLoader.vue";
import {useUserStore} from "../stores/user.js";
import {storeToRefs} from "pinia";
import NotificationBox from "./NotificationBox.vue";
import UserAvatar from "./UserAvatar.vue";
import {URLS_FACTORY} from "@/router/urls.js";
import {ref, watch} from "vue";

const $router = useRouter();
const $route = useRoute();
const userStore = useUserStore();
const {isLoggedIn, isClient} = storeToRefs(userStore);
const search = ref(null);
const search_min_length = Number(import.meta.env.VITE_SEARCH_MIN_LENGTH);

function homeNavigation(){
  $router.push(URLS_FACTORY.URLS_PATHS.ROOT);
}

watch(search, () => {
  if(!search.value || search.value.length < search_min_length){
    return;
  }
  $router.push(URLS_FACTORY.getURL(URLS_FACTORY.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_SEARCH, {search: search.value}));
});

watch($route, () => {
  if($router.options.history.state.back && $router.options.history.state.back.startsWith(URLS_FACTORY.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_SEARCH) && !$router.options.history.state.current.startsWith(URLS_FACTORY.URLS_FULL_PATHS.FULL_DASHBOARD_ADMIN_SEARCH)){
    search.value = null;
  }
});
</script>

<style lang="less" scoped>
@width-logo: 187px;

//barra di ricerca
.container-search{
  flex-grow: 1;
  display: inline-flex;
  justify-content: center;
}

//sezione delle icone
.wrap-top-action{
  display: flex;
  align-items: center;
}

.top-bar {
  background: @primary-color !important;

  //un elemento del menu che può avere dei sotto elementi
  .item-category {

    & > div {
      height: 100%;
    }
  }

  // elemento attivo del menu
  .active-menu {
    position: relative;
  }
}

.link-login{
  color:@white;
  text-decoration: none;
  font-weight: 700;
}

.logo {
  width: @width-logo;
  height: 65px;
}

.v-app-bar-title{
  max-width: 200px;
  min-width: 200px;
}
</style>